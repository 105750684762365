import { useTranslation } from 'react-i18next'

import { ActionModal, Modal } from '@percent/lemonade'
import { CancelOpportunityModalProps } from './CancelOpportunityModal.types'

export function CancelOpportunityModal({
  open,
  handleClose,
  handleCancelOpportunity,
  isLoading,
  opportunityName
}: Readonly<CancelOpportunityModalProps>) {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={handleClose}>
      <ActionModal
        title={t('typography.volunteering.cancelOpportunity.title')}
        primaryButtonText={t('button.close')}
        secondaryButtonText={t('button.cancel')}
        handleClose={handleClose}
        handleSubmit={handleCancelOpportunity}
        loading={isLoading}
      >
        {t('typography.volunteering.cancelOpportunity.description', {
          opportunityName
        })}
      </ActionModal>
    </Modal>
  )
}
