import { useContext } from 'react'

import { AbilitiesObject } from './usePermissionCheck.types'
import { IamStateContext } from '@percent/partner-dashboard/context/iam/iamContext/IamContext'
import { AbilityKey } from '@percent/partner-dashboard/context/iam/abilityDefinitions'

const defaultAbilitiesObject: AbilitiesObject = {
  manageAdmins: false,
  manageDevelopers: false,
  manageMembers: false,
  manageOwners: false,
  viewApiKeys: false,
  viewDashboard: false,
  viewDonations: false,
  viewOpportunities: false
}

export const usePermissionCheck = () => {
  const context = useContext(IamStateContext)
  if (context === undefined) {
    throw new Error('useAllow must be used within a IamContext')
  }

  const checkAbilities = (permissions: AbilityKey[] | AbilityKey): boolean => {
    const abilities = { ...defaultAbilitiesObject }
    Object.keys(defaultAbilitiesObject).forEach(permissionName => {
      context.abilityList[permissionName as unknown as AbilityKey].forEach(rule => {
        if (context.permissions.includes(rule)) {
          abilities[permissionName as unknown as AbilityKey] = true
        }
      })
    })

    if (typeof permissions === 'string') {
      return abilities[permissions]
    }
    return permissions.every(permission => abilities[permission])
  }

  return { userCan: checkAbilities }
}
