import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { ChangeEvent, EventHandler, KeyboardEvent, useMemo, useState } from 'react'

import {
  SearchInput,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableProvider,
  TableRow,
  TableSearchAndFilters,
  Tooltip,
  useToast
} from '@percent/lemonade'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { useMutation } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { VolunteeringTableProps } from './VolunteeringTable.types'
import styles from './VolunteeringTable.module.scss'
import {
  CancelOpportunityErrorCode,
  OpportunityType
} from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'
import { getFormattedDate } from '@percent/partner-dashboard/common/library/utility/date'

import { VolunteeringActionsMenu } from '../VolunteeringActionsMenu/VolunteeringActionsMenu'
import { CancelOpportunityModal } from '../CancelOpportunity/Modal/CancelOpportunityModal'
import { CancelOpportunityErrorModal } from '../CancelOpportunity/ErrorModal/CancelOpportunityErrorModal'

export function VolunteeringTable({
  data,
  isLoading,
  totalResults,
  errorMessage,
  nextPage,
  previousPage,
  queryParams,
  setQueryParams,
  refresh
}: Readonly<VolunteeringTableProps>) {
  const { t } = useTranslation()
  const history = useHistory()
  const { workplaceGivingService } = useServices()
  const { addToast } = useToast()

  const [searchValue, setSearchValue] = useState<string>(queryParams?.query || '')
  const [selectedOpportunity, setSelectedOpportunity] = useState<{ id: string; name: string } | undefined>()
  const [cancelErrorModalOpen, setCancelErrorModalOpen] = useState(false)

  const [{ isLoading: isCancelLoading, error }, { apiFunc: cancelOpportunity }] = useMutation(
    workplaceGivingService.cancelVolunteeringOpportunity,
    () => {
      addToast(t('typography.volunteering.cancelOpportunitySuccess'), 'success')
      refresh()
    },
    error => {
      if (error) {
        setCancelErrorModalOpen(true)
      }
    }
  )

  const handleCancelOpportunity = () => {
    if (selectedOpportunity?.id) {
      cancelOpportunity(selectedOpportunity.id)
      setSelectedOpportunity(undefined)
    }
  }

  const columns = useMemo(
    () => [
      { id: t('table.header.name'), props: { width: '25%' } },
      { id: t('table.header.nonprofit'), props: { width: '18%' } },
      { id: t('table.header.owner'), props: { width: '18%' } },
      { id: t('table.header.attendees'), props: { width: '14%' } },
      { id: t('table.header.createdAt'), props: { width: '14%' } },
      { id: t('table.header.actions'), props: { width: '5%' } }
    ],
    [t]
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (searchValue.length <= 255) {
      if (event.key === 'Enter') {
        setQueryParams?.({
          query: (event.target as HTMLInputElement).value.trim()
        })
      }
    }

    if (event.key === 'Escape') {
      setQueryParams?.({
        query: ''
      })
      return setSearchValue('')
    }

    return
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <TableProvider
        columns={columns}
        totalResults={totalResults}
        isLoading={isLoading || !data}
        illustration="no-results"
        emptyTableTitle={
          queryParams?.query
            ? t('typography.noSearchVolunteeringOpportunitiesTitle', {
                searchTerm: queryParams?.query
              })
            : t('typography.noVolunteeringOpportunitiesTitle')
        }
        emptyTableDescription={
          queryParams?.query
            ? t('typography.noSearchVolunteeringOpportunitiesDescription')
            : t('typography.noVolunteeringOpportunitiesDescription')
        }
      >
        <TableSearchAndFilters>
          <SearchInput
            name="search"
            value={searchValue}
            onKeyDown={handleKeyPress}
            onChange={handleChange}
            handleClearValue={() => {
              setSearchValue('')
              setQueryParams?.({
                query: null
              })
            }}
            placeholder={t('table.volunteeringSearchPlaceholder')}
            status={searchValue.length >= 255 ? 'danger' : 'default'}
            statusMessage={t('errorMessage.shouldNotExceed255')}
          />
        </TableSearchAndFilters>
        <Table>
          <TableBody>
            {data?.map(opportunity => {
              const opportunityHref = `${process.env.NX_WPG_ADDRESS}/volunteer/opportunities/${opportunity.id}`
              const OpportunityLink = (
                <a className={styles.link} href={opportunityHref} target="_blank" rel="noopener noreferrer">
                  {opportunity.name.length > 38 ? `${opportunity.name.substring(0, 35)}...` : opportunity.name}
                </a>
              )

              return (
                <TableRow key={opportunity.id}>
                  <TableCell>
                    {opportunity.name.length > 38 ? (
                      <Tooltip content={opportunity.name}>{OpportunityLink}</Tooltip>
                    ) : (
                      OpportunityLink
                    )}
                  </TableCell>
                  <TableCell>
                    {opportunity.organisation?.displayName ||
                      opportunity.organisation?.name ||
                      t('status.notAvailable')}
                  </TableCell>
                  <TableCell>{opportunity.organiser?.email || t('status.notAvailable')}</TableCell>
                  <TableCell>
                    {opportunity.type === OpportunityType.EVENT_SERIES ? (
                      <Link className={styles.link} to={`/workplace-giving/volunteering/${opportunity.id}`}>
                        {t('volunteering.multipleSessions')}
                      </Link>
                    ) : (
                      `${opportunity.participants.count}${
                        opportunity.participants.spots ? `/${opportunity.participants.spots}` : ''
                      }`
                    )}
                  </TableCell>
                  <TableCell>{getFormattedDate(opportunity.createdAt)}</TableCell>
                  <TableCell>
                    <VolunteeringActionsMenu
                      items={[
                        {
                          key: 'view',
                          label: t('button.view')
                        },
                        ...(opportunity.type === OpportunityType.EVENT_SERIES
                          ? [
                              {
                                key: 'seeSessions',
                                label: t('button.seeSessions')
                              }
                            ]
                          : []),
                        {
                          key: 'cancel',
                          label: t('button.cancel')
                        }
                      ]}
                      handleSelect={(actionKey: string) => {
                        switch (actionKey) {
                          case 'view':
                            window.open(opportunityHref, '_blank', 'noopener noreferrer')
                            break
                          case 'seeSessions':
                            history.push(`/workplace-giving/volunteering/${opportunity.id}`)
                            break
                          case 'cancel':
                            setSelectedOpportunity({
                              id: opportunity.id,
                              name: opportunity.name
                            })
                            break
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          totalResultsText={t('table.results', { count: totalResults || undefined })}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      </TableProvider>
      {selectedOpportunity ? (
        <CancelOpportunityModal
          open={!!selectedOpportunity}
          opportunityName={selectedOpportunity.name}
          handleCancelOpportunity={handleCancelOpportunity}
          handleClose={() => {
            setSelectedOpportunity(undefined)
          }}
          isLoading={isCancelLoading}
        />
      ) : null}
      {error ? (
        <CancelOpportunityErrorModal
          open={cancelErrorModalOpen}
          handleClose={() => {
            setCancelErrorModalOpen(false)
          }}
          errorCode={error.code as CancelOpportunityErrorCode}
        />
      ) : null}
    </>
  )
}
