import { Loader, Spacer } from '@percent/lemonade'
import { VerificationDetailsProps } from './VerificationDetails.types'
import { VerificationSummaryCard } from './VerificationSummaryCard/VerificationSummaryCard'
import { ErrorView } from '@percent/partner-dashboard/common/components'
import { ValidationRequestRejectionReasonCodes } from '@percent/partner-dashboard/services/applications/applicationsService.types'
import {
  VerificationAgentDetailsCard,
  VerificationMetadataCard,
  VerificationOrganisationDetailsCard,
  VerificationPartnerFieldsCard,
  VerificationDocumentsCard
} from '@percent/domain/verifications'
import { useCountries } from '@percent/partner-dashboard/common/hooks/useCountries/useCountries'

export function VerificationDetails({
  data,
  documents,
  organisation,
  isLoadingValidationSubmissionDocuments,
  isLoading,
  isLoadingOrganisation,
  errorMessage
}: Readonly<VerificationDetailsProps>) {
  const { getCountryNameByCode } = useCountries()

  if ((isLoading || isLoadingValidationSubmissionDocuments) && !data) return <Loader />

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  const hasPartnerFields = !!Object.keys(data.partnerFields).length

  const hasNoEligibleOrganisationType =
    !!organisation &&
    data?.validationRequest?.rejectionReasonCode === ValidationRequestRejectionReasonCodes.NotEligible &&
    !data.configuration?.allowedOrganisations?.types?.some(item => organisation?.types.includes(item))

  const getAgentDetails = () => {
    if (data?.agentVerification) {
      return {
        agentName: `${data.agentVerification.firstName} ${data.agentVerification.lastName}`,
        agentEmail: data.agentVerification.email,
        language: data.agentVerification.language
      }
    }

    if (data.agentVerificationFirstName && data.agentVerificationLastName && data.agentVerificationEmail) {
      return {
        agentName: `${data.agentVerificationFirstName} ${data.agentVerificationLastName}`,
        agentEmail: data.agentVerificationEmail
      }
    }

    return null
  }

  const agentDetails = getAgentDetails()

  return (
    <div data-testid="verification-details">
      <VerificationSummaryCard
        verification={data}
        organisation={organisation}
        hasNoEligibleOrganisationType={hasNoEligibleOrganisationType}
      />

      {agentDetails && (
        <>
          <Spacer size={8} axis="vertical" />
          <VerificationAgentDetailsCard
            agentName={agentDetails.agentName}
            agentEmail={agentDetails.agentEmail}
            language={agentDetails.language}
          />
        </>
      )}

      <Spacer size={8} axis="vertical" />
      <VerificationOrganisationDetailsCard
        organisation={organisation}
        isLoading={isLoadingOrganisation}
        validationRequest={data.validationRequest}
        eligibility={data.eligibility}
        hasNoEligibleOrganisationType={hasNoEligibleOrganisationType}
        getCountryNameByCode={getCountryNameByCode}
      />

      {hasPartnerFields && (
        <>
          <Spacer size={8} axis="vertical" />
          <VerificationPartnerFieldsCard partnerFields={data.partnerFields} />
        </>
      )}

      <Spacer size={8} axis="vertical" />
      <VerificationDocumentsCard documents={documents} isLoading={isLoadingValidationSubmissionDocuments} />

      {data.metadata && !!Object.keys(data.metadata).length && (
        <>
          <Spacer size={8} axis="vertical" />
          <VerificationMetadataCard metadata={data.metadata} />
        </>
      )}
    </div>
  )
}
