import { UpdatedEmployee } from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'
import { useState } from 'react'

export function useEmployeesActionModal() {
  const [isAddWalletBalanceModalOpen, setIsAddWalletBalanceModalOpen] = useState<boolean>(false)
  const [selectedEmployee, setSelectedEmployee] = useState<UpdatedEmployee | null>(null)
  const [isUpdateMatchingBudgetModalOpen, setIsUpdateMatchingBudgetModalOpen] = useState<boolean>(false)
  const [isAssignAchievementModalOpen, setIsAssignAchievementModalOpen] = useState<boolean>(false)

  const openAddWalletBalanceModal = (employee: UpdatedEmployee) => {
    setSelectedEmployee(employee)
    setIsAddWalletBalanceModalOpen(true)
  }

  const closeAddWalletBalanceModal = () => {
    setIsAddWalletBalanceModalOpen(false)
  }

  const openUpdateMatchingBudgetModal = (employee: UpdatedEmployee) => {
    setSelectedEmployee(employee)
    setIsUpdateMatchingBudgetModalOpen(true)
  }

  const closeUpdateMatchingBudgetModal = () => {
    setIsUpdateMatchingBudgetModalOpen(false)
  }

  const openAssignAchievementBudgetModal = (employee: UpdatedEmployee) => {
    setSelectedEmployee(employee)
    setIsAssignAchievementModalOpen(true)
  }

  const closeAssignAchievementBudgetModal = () => {
    setIsAssignAchievementModalOpen(false)
  }

  return {
    selectedEmployee,
    isAddWalletBalanceModalOpen,
    openAddWalletBalanceModal,
    closeAddWalletBalanceModal,
    isUpdateMatchingBudgetModalOpen,
    openUpdateMatchingBudgetModal,
    closeUpdateMatchingBudgetModal,
    isAssignAchievementModalOpen,
    openAssignAchievementBudgetModal,
    closeAssignAchievementBudgetModal
  }
}
