import { useDidMountEffect, useQueryList } from '@percent/partner-dashboard/common/hooks'
import { useServices } from '@percent/partner-dashboard/context/serviceContext/ServiceContext'
import { StringParam, useQueryParams } from 'use-query-params'
import { VolunteeringTable } from './VolunteeringTable/VolunteeringTable'

export function VolunteeringContainer() {
  const { workplaceGivingService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams(
    {
      query: StringParam,
      parentId: StringParam
    },
    {}
  )

  const [{ data, isLoading, errorMessage, totalResults }, { query, nextPage, previousPage, refresh }] = useQueryList(
    workplaceGivingService.getVolunteeringOpportunitiesList,
    {
      ...queryParams
      // todo: uncomment once BE ready
      // parentId: null
    }
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <VolunteeringTable
      data={data}
      isLoading={isLoading}
      totalResults={totalResults}
      errorMessage={errorMessage}
      nextPage={nextPage}
      previousPage={previousPage}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
