import React from 'react'
import styled, { css } from 'styled-components'
import {
  ProgressBarProps,
  sizeMap,
  StyledProgressBarProps,
  StyledProgressBarShadeProps,
  StyledProgressBarWrapperProps,
} from './progress-bar.types'

const ProgressBarWrapper = styled.div<StyledProgressBarWrapperProps>`
  ${({ $size }) => css`
    position: relative;
    width: 100%;
    height: ${sizeMap[$size]};
    margin: 8px 0;
    background-color: ${({ theme }) => theme.gray70};
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
  `}
`

const CommonProgressBar = styled.div`
  background-color: ${({ theme }) => theme.info400};
  max-width: 100% !important;
  transition: all 0.4s ease-in-out;
  transition-delay: 1s;
`

const StyledProgressBar = styled(CommonProgressBar)<StyledProgressBarProps>`
  ${({
    theme,
    $isProgressBarFull,
    progressPercentage,
    $backgroundColorComplete,
    $backgroundColorIncomplete,
  }) => css`
    width: ${progressPercentage}%;
    background-color: ${$isProgressBarFull
      ? theme.colors[$backgroundColorComplete]
      : theme.colors[$backgroundColorIncomplete]};
  `}
`

const StyledProgressBarShade = styled(
  CommonProgressBar
)<StyledProgressBarShadeProps>`
  ${({
    theme,
    $isProgressBarFull,
    $backgroundColorComplete,
    $backgroundColorIncomplete,
    $hasOpacity,
  }) => css`
    flex: 1;
    background-color: ${$isProgressBarFull
      ? theme.colors[$backgroundColorComplete]
      : theme.colors[$backgroundColorIncomplete]};
    opacity: ${$hasOpacity ? 0.4 : 1};
    transition-delay: 0s;
  `}
`

export const ProgressBar = ({
  progressPercentage,
  backgroundColorComplete,
  backgroundColorIncomplete,
  shadeColor,
  sizeVariant = 'medium',
}: ProgressBarProps) => {
  const progressPercentageArray = Array.isArray(progressPercentage)
    ? progressPercentage
    : [progressPercentage]

  const backgroundColorCompleteArray = Array.isArray(backgroundColorComplete)
    ? backgroundColorComplete
    : [backgroundColorComplete]

  const backgroundColorIncompleteArray = Array.isArray(
    backgroundColorIncomplete
  )
    ? backgroundColorIncomplete
    : [backgroundColorIncomplete]

  if (
    progressPercentageArray.length !== backgroundColorCompleteArray.length ||
    progressPercentageArray.length !== backgroundColorIncompleteArray.length
  ) {
    console.error('[ProgressBar] Please provide colors for all values')
    return null
  }

  const isProgressBarFull =
    progressPercentageArray.reduce((sum, value) => sum + value, 0) >= 100
  const lastBarIndex = progressPercentageArray.length - 1

  return (
    <ProgressBarWrapper $size={sizeVariant} data-testid="progress-bar-wrapper">
      {progressPercentageArray.map((value, index) => (
        <StyledProgressBar
          key={`bar-${index}`}
          progressPercentage={value}
          $isProgressBarFull={isProgressBarFull}
          $backgroundColorComplete={backgroundColorCompleteArray[index]}
          $backgroundColorIncomplete={backgroundColorIncompleteArray[index]}
        />
      ))}

      <StyledProgressBarShade
        $isProgressBarFull={isProgressBarFull}
        $backgroundColorComplete={
          shadeColor || backgroundColorCompleteArray[lastBarIndex]
        }
        $backgroundColorIncomplete={
          shadeColor || backgroundColorIncompleteArray[lastBarIndex]
        }
        $hasOpacity={!shadeColor}
      />
    </ProgressBarWrapper>
  )
}
