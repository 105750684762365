import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, Modal } from '@percent/lemonade'
import { CancelOpportunityErrorModalProps } from './CancelOpportunityErrorModal.types'
import { CancelOpportunityErrorCode } from '@percent/partner-dashboard/services/workplaceGiving/workplaceGivingService.types'

export function CancelOpportunityErrorModal({
  open,
  handleClose,
  errorCode
}: Readonly<CancelOpportunityErrorModalProps>) {
  const { t } = useTranslation()

  const errorCodesMap: Record<CancelOpportunityErrorCode, string> = {
    'opportunity/cannot_be_cancelled': t('errorMessage.cancelOpportunity.cannotBeCancelled'),
    'opportunity/cancel_invalid_state': t('errorMessage.cancelOpportunity.cancelInvalidState'),
    'opportunity/cannot_have_published_children': t('errorMessage.cancelOpportunity.cannotHavePublishedChildren'),
    'opportunity/external': t('errorMessage.cancelOpportunity.externalOpportunity')
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <AcknowledgeModal
        result="negative"
        title={t('typography.volunteering.cancelOpportunityErrorModal.title')}
        description={errorCodesMap[errorCode] ?? t('errorMessage.genericError')}
        buttonText={t('button.close')}
        handleClose={handleClose}
      />
    </Modal>
  )
}
